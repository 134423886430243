import { Form } from "reactstrap";
import { FormCheckbox } from "../../components/form/FormCheckbox";
import { FormInput } from "../../components/form/FormInput";
import { FormSelect } from "../../components/form/FormSelect";
import { ReactFormMultiSelect } from "../../components/form/ReactFormSelect";
import { SubmitButton } from "../../components/form/SubmitButton";
import { ErrorHandler } from "../../components/layout/ErrorDisplay";
import { CONTRACTOR_SEGMENTS } from "../../constants";
import { handleAPIError } from "../../services/utils";
import { ContactPerson } from "./ContactPerson";
import { DynamicMultiInput } from "./DynamicMultiInput";
import { MarketShare } from "./MarketShare";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useWatch, useFieldArray } from "react-hook-form";
import { useScrollToError } from "../../hooks/useScrollToError";
import {
  CompanyDataForm,
  CreateCompanyFormData,
  CreateCompanySchema,
} from "./create-company.schema";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useCompanyAPI } from "../../services/companies.service";
import { useEffect } from "react";
import { useDealerAPI } from "../../services/dealers.service";

interface Props {
  onSubmit: (formData: CreateCompanyFormData) => void;
  isEditMode?: boolean;
  responseError?: unknown;
  initFormValues?: any;
}

export function CompanyForm({ onSubmit, responseError = "" }: Props) {
  const { companyID } = useParams();

  const { getCompanyByID } = useCompanyAPI();
  const { fetchDealers } = useDealerAPI();

  const {
    data: companyData,
    status: companyQueryStatus,
    error: companyQueryError,
  } = useQuery(["companies", companyID], () => getCompanyByID(companyID), {
    enabled: !!companyID,
  });

  const { data: dealers } = useQuery([], () => fetchDealers());

  const { handleSubmit, control, formState, reset } = useForm<CompanyDataForm>({
    resolver: yupResolver(CreateCompanySchema),
    defaultValues: companyData || new CompanyDataForm({}),
    shouldFocusError: false,
  });

  useEffect(() => {
    if (companyData) {
      reset(companyData);
    }
  }, [companyData, reset]);

  const { onError } = useScrollToError<CreateCompanyFormData>(formState);

  const serviceProviderTypeValue = useWatch({
    control,
    name: "serviceProviderType",
  });

  const {
    fields: servicePartners,
    append: addServicePartner,
    remove: removeServicePartner,
  } = useFieldArray({
    control: control,
    name: "servicePartners",
  });
  const {
    fields: powerToolBrandsUsed,
    append: addPTBrand,
    remove: removePTBrand,
  } = useFieldArray({
    control: control,
    name: "powerToolBrandsUsed",
  });
  const {
    fields: accessoryBrandsUsed,
    append: addACBrand,
    remove: removeACBrand,
  } = useFieldArray({
    control: control,
    name: "accessoryBrandsUsed",
  });
  const {
    fields: measuringToolBrandsUsed,
    append: addMTBrand,
    remove: removeMTBrand,
  } = useFieldArray({
    control: control,
    name: "measuringToolBrandsUsed",
  });

  const {
    fields: contacts,
    append: addContact,
    remove: removeContact,
  } = useFieldArray({
    control: control,
    name: "contacts",
  });

  if (!!companyID && companyQueryStatus === "loading")
    return <p>Loading company {companyID} details...</p>;

  if (!!companyID && companyQueryStatus === "error")
    return <ErrorHandler error={handleAPIError(companyQueryError)} />;

  return (
    <Form onSubmit={handleSubmit(onSubmit, onError)} className={"py-2"}>
      <FormInput name={"name"} label={"Name"} control={control} />
      <ReactFormMultiSelect
        name="businessSegments"
        control={control}
        options={CONTRACTOR_SEGMENTS.sort()}
        label="Business Segments"
      />
      <FormInput name={"town"} label={"Location/Town"} control={control} />
      <FormInput name={"street"} label={"Street"} control={control} />
      <FormSelect
        name={"numberOfEmployees"}
        options={[
          "1-5",
          "6-15",
          "16-30",
          "31-60",
          "61-200",
          "201-500",
          "more than 500",
        ]}
        label={"Number of Employees"}
        control={control}
      />
      <ContactPerson
        addContact={addContact}
        control={control}
        errors={formState.errors}
        fieldArray={contacts}
        removeContact={removeContact}
      />
      <FormSelect
        name={"numberOfPowerTools"}
        options={[
          "0",
          "1-10",
          "11-25",
          "26-70",
          "71-150",
          "151-500",
          "more than 500",
        ]}
        label={"Number of Power Tools"}
        control={control}
      />
      <MarketShare
        fieldName="powerToolBrandsUsed"
        addBrand={addPTBrand}
        control={control}
        errors={formState.errors}
        fieldArray={powerToolBrandsUsed}
        removeBrand={removePTBrand}
        labelText="Which power tool brands does the company use?"
      />
      <MarketShare
        fieldName="accessoryBrandsUsed"
        addBrand={addACBrand}
        control={control}
        errors={formState.errors}
        fieldArray={accessoryBrandsUsed}
        removeBrand={removeACBrand}
        labelText="Which accessories brands does the company use?"
      />
      <FormInput
        label={"How many measuring tools does the business use?"}
        type="number"
        name={"numberOfMeasuringTools"}
        control={control}
      />
      <MarketShare
        fieldName="measuringToolBrandsUsed"
        control={control}
        errors={formState.errors}
        fieldArray={measuringToolBrandsUsed}
        addBrand={addMTBrand}
        removeBrand={removeMTBrand}
        labelText="Which measuring tool brands does the company use?"
      />

      <ReactFormMultiSelect
        name="dealerPurchaseFrom"
        options={dealers || ["Dealer list not found"]}
        control={control}
        label="Which dealer(s) does the company purchase their tools and accessories from?"
      />

      <FormCheckbox
        type="radio"
        options={["Yes", "No", "n/a"]}
        label={"Does the company make use of a full-service offer of Bosch?"}
        name="useBoschFullService"
        control={control}
      />

      <ReactFormMultiSelect
        name="serviceProviderType"
        options={["In-house (own technicians)", "Service Partner"]}
        control={control}
        label="Where does the company service their tools?"
      />

      {serviceProviderTypeValue.includes("Service Partner") ? (
        <DynamicMultiInput
          control={control}
          addName={addServicePartner}
          removeName={removeServicePartner}
          errors={formState.errors}
          labelText="Name of the service partner"
          fieldArray={servicePartners}
          fieldName="servicePartners"
          placeHolderText="Service Partner"
        />
      ) : null}

      {responseError ? (
        <ErrorHandler error={handleAPIError(responseError)} />
      ) : null}
      <div className="d-grid py-3">
        <SubmitButton
          isLoading={
            formState.isValid &&
            (formState.isSubmitting || formState.isSubmitSuccessful)
          }
        />
      </div>
    </Form>
  );
}
