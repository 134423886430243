import { PT_API_URL } from "../constants";
import { useAxiosInstance } from "../hooks/useAxiosInstance";
import { GetDealerDto } from "./dtos/dealers.dto";

export function useDealerAPI() {
  const axiosInstance = useAxiosInstance();

  const fetchDealers = async () => {
    const { data } = await axiosInstance.get<GetDealerDto[]>(
      PT_API_URL.concat(`dealers`)
    );
    return data.map((it) => it.name.toUpperCase());
  };

  return { fetchDealers };
}
